import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/selectors';
import Account from './Account';
import Brands from './Brands';
import TeamMembers from './TeamMembers';
import { UserRoles } from 'constants/UserRoles';

const SettingsSidebar = ({ active, setActive, user }) => {
  return (
    <aside className="menu">
      <p className="menu-label">General</p>
      <ul className="menu-list">
        <li>
          <a
            onClick={() => {
              setActive('Account');
              window.history.replaceState(null, 'Account', '/settings/account');
            }}
            className={`${active === 'Account' ? 'is-active' : ''}`}
          >
            <i className="fas fa-user-circle margin-right-5" /> Account
          </a>
        </li>

        {user.role === UserRoles.ADMIN && (
          <>
            <li>
              <a
                onClick={() => {
                  setActive('Brand Identities');
                  window.history.replaceState(
                    null,
                    'Brand Identities',
                    '/settings/brands'
                  );
                }}
                class={`${active === 'Brand Identities' ? 'is-active' : ''}`}
              >
                <i class="fas fa-swatchbook margin-right-5" /> Brand Identities
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setActive('Team Members');
                  window.history.replaceState(
                    null,
                    'Team Members',
                    '/settings/members'
                  );
                }}
                class={`${active === 'Team Members' ? 'is-active' : ''}`}
              >
                <i class="fas fa-users margin-right-5" /> Team Members
              </a>
            </li>
          </>
        )}
      </ul>
    </aside>
  );
};

const Settings = (props) => {
  const [active, setActive] = useState(props.active || 'Account');
  const [inviteModal, showInviteModal] = useState(false);
  const [brandModal, showBrandModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const user = useSelector(getUser);

  useEffect(() => {
    if (user.svCustomer && window.location.pathname == '/settings/billing') {
      window.location.href = '/settings/account';
    }
  }, []);

  useEffect(() => {
    if (user) setLoading(false);
  }, [user]);

  const actionButton = () => {
    switch (active) {
      case 'Team Members': {
        return (
          <button
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            className="button upload is-info is-small action_button"
            onClick={(e) => showInviteModal(true)}
          >
            <span className="icon">
              <i className="menu far fa-paper-plane" />
            </span>
            <span>Invite colleague</span>
          </button>
        );
      }
      case 'Brand Identities': {
        return (
          <button
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            className="button upload is-info is-small action_button"
            onClick={(e) => showBrandModal(true)}
          >
            <span className="icon">
              <i className="menu fa fa-plus" />
            </span>
            <span>New Brand Identity</span>
          </button>
        );
      }
      default:
        return null;
    }
  };
  const renderLoading = () => {
    return (
      <div className="fullwidth_loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };
  const renderContent = () => {
    return (
      <div className="container">
        <div className="box page-box">
          <div className="columns">
            <div className="column">
              <SettingsSidebar
                user={user}
                active={active}
                setActive={setActive}
              />
            </div>
            <div className="column is-three-quarters">
              {active === 'Account' && <Account />}
              {active === 'Brand Identities' && (
                <Brands newModal={brandModal} setNewModal={showBrandModal} />
              )}
              {active === 'Team Members' && (
                <TeamMembers
                  inviteModal={inviteModal}
                  showInviteModal={showInviteModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Page title={active} action={actionButton()}>
      <div className="page settings_page">
        {loading ? renderLoading() : renderContent()}
      </div>
    </Page>
  );
};

export default Settings;
