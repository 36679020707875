import Page from 'components/Page';
import React, { useEffect, useMemo, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocuments } from 'redux/actions';
import {
  fetchWorkflowHistory,
  fetchWorkflows
} from 'redux/actions/workflowActions';
import {
  getDocuments,
  getWorkflowHistory,
  getWorkflows,
  isActionPending
} from 'redux/selectors';
import DocumentCard from '../Documents/DocumentCard';
import MessageBox from '../Page/MessageBar';
import WorkflowBox from '../Workflows/WorkflowBox';
import './Dashboard.scss';
import OnboardingWidget from './OnboardingWidget';

const Dashboard = (props) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const documents = useSelector(getDocuments);
  const workflows = useSelector(getWorkflows);
  const pendingDocuments = useSelector((state) =>
    isActionPending(state, 'GET_DOCUMENTS')
  );
  const pendingWorkflows = useSelector((state) =>
    isActionPending(state, 'GET_WORKFLOWS')
  );
  const pendingHistory = useSelector((state) =>
    isActionPending(state, 'GET_WORKFLOWRUNS')
  );

  const showLoadingDocuments =
    (!documents || !documents.length) &&
    pendingDocuments &&
    pendingDocuments.pending;
  const showLoadingWorkflows =
    (!workflows || !workflows.length) &&
    pendingWorkflows &&
    pendingWorkflows.pending;

  const documentList = useMemo(() => documents, [documents]);
  const workflowList = useMemo(() => workflows, [workflows]);
  const whistory = useSelector(getWorkflowHistory);

  useEffect(() => {
    dispatch(fetchDocuments());
    dispatch(fetchWorkflows());
    dispatch(fetchWorkflowHistory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pendingDocuments && pendingWorkflows && pendingHistory) {
      if (!pendingHistory.pending) {
        if (!pendingDocuments.pending) {
          if (!pendingWorkflows.pending) {
            setLoading(false);
          }
        }
      }
    }
  });

  const renderLoading = () => {
    return (
      <div className="dashboard_loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };

  const renderTop = () => {
    return (
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            {/* <h4 className="title is-4">Welcome back,</h4> */}
          </div>
        </div>
      </nav>
    );
  };

  const renderDocumentWidget = () => {
    return (
      <React.Fragment>
        <section className="section documents">
          <div className="container">
            <div className="tile is-ancestor">
              <div className="tile is-parent is-4 flex">
                {documentList.slice(0, 4).map((d) => (
                  <DocumentCard data={d} />
                ))}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  };
  const renderWorkflowsWidget = () => {
    return (
      <section className="section recent_workflows">
        <div className="container">
          <h3 className="title is-4">Recent Workflows</h3>
          {workflowList.slice(0, 4).map((w) => (
            <WorkflowBox data={w} />
          ))}
        </div>
      </section>
    );
  };
  const renderContent = () => {
    const loaded =
      !showLoadingDocuments &&
      !showLoadingWorkflows &&
      workflows &&
      workflows.length > 0 &&
      documents &&
      documents.length > 0 &&
      whistory &&
      whistory.length > 0;
    if (loaded) {
      return (
        <React.Fragment>
          {showLoadingDocuments ? renderLoading() : renderDocumentWidget()}
          {showLoadingWorkflows ? renderLoading() : renderWorkflowsWidget()}
        </React.Fragment>
      );
    } else {
      return (
        <div className="onboarding-wrapper">
          <OnboardingWidget />
        </div>
      );
    }
  };

  return (
    <Page title="Dashboard">
      <div className="dashboard page">
        <section className="section">
          <div className="container">
            <MessageBox />
            {renderTop()}
          </div>
        </section>
        {loading ? renderLoading() : renderContent()}
      </div>
    </Page>
  );
};
export default Dashboard;
