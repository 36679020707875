import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { cloneDocument } from 'redux/actions/documentActions';

const DuplicateDocumentModal = ({ open, onClose, id, name }) => {
  const dispatch = useDispatch();

  const onPress = () => {
    dispatch(cloneDocument(id));
    toast.info('Duplicating and processing the document...');
    onClose();
  };
  return (
    <div className={`modal ${open && 'is-active'}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title is-small">Duplicate Document?</p>
        </header>
        <section className="modal-card-body" style={{ fontSize: '14px' }}>
          Are you sure you want to duplicate {name}?
        </section>
        <footer className="modal-card-foot">
          <button className="button is-small" onClick={(e) => onClose()}>
            Cancel
          </button>
          <button className="button is-small is-info" onClick={onPress}>
            Duplicate
          </button>
        </footer>
      </div>
    </div>
  );
};
export default DuplicateDocumentModal;
