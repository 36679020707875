import server from 'api/server';
import Page from 'components/Page';
import { GlobalSearch } from 'components/_common/TableElements';
import React, { useEffect, useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteDocument, fetchDocuments } from 'redux/actions';
import {
  setCurrentFolder,
  setFolderNavigationHistory
} from 'redux/actions/folderActions';
import { getDocuments } from 'redux/selectors';
import './Documents.scss';
import FileExplorer from './FileExplorer';
import DeleteDocumentsModal from './FileExplorer/components/DeleteDocumentsModal';

import FormLibraryModal from './FormLibrary';
import Uploader from './Uploader';

const UserDocuments = (props) => {
  const [isUploading, setIsUploading] = useState(false);
  const [formLibraryOpen, setFormLibraryOpen] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [searchText, setSearchText] = useState('');
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [multipleDeleteModalOpen, setMultipleDeleteModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [tree, setTree] = useState(null);

  const currentFolder = useSelector((state) => state.files.currentFolder);
  const setcurrentFolder = (folder) => dispatch(setCurrentFolder(folder));
  const folderHistory = useSelector(
    (state) => state.files.folderNavigationHistory
  );
  const setHistory = (history) => dispatch(setFolderNavigationHistory(history));

  const dispatch = useDispatch();
  const documents = useSelector(getDocuments);

  const fetchFiles = async () => {
    setFetchingFiles(true);
    dispatch(fetchDocuments());
    const {
      data: { files, tree }
    } = await server.get('/folders/all');
    setFiles(files);
    setTree(tree);
    setFetchingFiles(false);
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  useEffect(() => {
    let interval;
    if (documents.find((doc) => doc.processing)) {
      interval = setInterval(() => {
        fetchFiles();
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [dispatch, documents, files]);

  useEffect(() => {
    const rogue = documents.find(
      (doc) => doc.processing === false && doc.processingSuccessful === false
    );
    if (rogue) {
      toast.info('Document could not be processed...');
      dispatch(deleteDocument(rogue.id));
    }
  }, [documents]);

  const handleDocumentSelected = ({
    allSelected,
    selectedCount,
    selectedRows
  }) => {
    const selected = selectedRows.map(({ id }) => id);
    setSelectedDocuments(selected);
  };

  return (
    <Page
      title="Documents"
      action={[
        <button
          onClick={(e) => {
            setFormLibraryOpen(!formLibraryOpen);
          }}
          className="button upload is-small is-info form_library"
        >
          <span className="icon">
            <i className="fas fa-folder-open"></i>
          </span>
          <span>Add From Library</span>
        </button>,

        <button
          onClick={(e) => {
            setIsUploading(!isUploading);
          }}
          className="button upload is-small is-info"
        >
          <span className="icon">
            <i className="fas fa-upload"></i>
          </span>
          <span>Upload New Document</span>
        </button>
      ]}
      filters={[
        <GlobalSearch
          globalFilter={searchText}
          setGlobalFilter={setSearchText}
        />,
        <div>
          <button
            onClick={() => {
              setMultipleDeleteModalOpen(true);
            }}
            className="button delete-button is-small"
            disabled={selectedDocuments.length === 0}
          >
            <span className="icon">
              <i className="far fa-trash-alt"></i>
            </span>
            <span>Delete</span>
          </button>
        </div>
      ]}
    >
      <FormLibraryModal
        open={formLibraryOpen}
        onClose={() => {
          setFormLibraryOpen(false);
        }}
        currentFolderId={currentFolder}
      />
      <div className="page user_documents">
        {isUploading && (
          <div className="box">
            <Uploader
              fetchFiles={fetchFiles}
              currentFolder={currentFolder}
              onSuccess={() => {
                setIsUploading(false);
              }}
            />
          </div>
        )}
        <div className="container">
          <DeleteDocumentsModal
            open={multipleDeleteModalOpen}
            onClose={() => {
              setMultipleDeleteModalOpen(false);
            }}
            onSuccess={() => {
              fetchFiles();
              setSelectedDocuments([]);
            }}
            documents={selectedDocuments}
          />
          <FileExplorer
            loading={fetchingFiles}
            history={folderHistory}
            setHistory={setHistory}
            currentFolder={currentFolder}
            setcurrentFolder={setcurrentFolder}
            files={files}
            folderTree={tree}
            sortBy={sortBy}
            fetchFiles={fetchFiles}
            handleDocumentSelected={handleDocumentSelected}
            selectedRows={selectedDocuments}
          />
        </div>
      </div>
    </Page>
  );
};
export default UserDocuments;
