import server from 'api/server';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import './style.scss';

const FormLibraryModal = ({ open, onClose, currentFolderId }) => {
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [processing, setProcessing] = useState(null);

  useEffect(() => {
    const getTemplates = async () => {
      setLoading(true);
      const { data } = await server.get(`/library`);
      setTemplates(data);
      setCategories(
        Array.from(new Set(data.map((d) => d.metadata.categories).flat()))
      );
      setSearch('');
      setLoading(false);
    };
    getTemplates();
  }, []);

  useEffect(() => {
    if (search && templates) {
      setSelectedCategory('All');
      const fuse = new Fuse(templates, {
        includeScore: false,
        keys: ['name', 'metadata.description']
      });
      const result = fuse.search(search);
      setFilteredTemplates(result.map((r) => r.item));
    } else {
      setFilteredTemplates(templates);
    }
  }, [search, templates]);
  useEffect(() => {
    if (selectedCategory === 'All') {
      setFilteredTemplates(templates);
    } else {
      setFilteredTemplates(
        templates.filter((template) =>
          template.metadata.categories.includes(selectedCategory)
        )
      );
    }
  }, [selectedCategory]);

  const onTemplateSelected = async (template) => {
    setProcessing(template);
    toast.info('Creating document from template, you will be redirected soon!');
    const { data } = await server.post(`/library/create`, {
      templateId: template.id
    });
    const { documentId } = data;
    const interval = setInterval(async () => {
      const { data } = await server.get(`/documents/get/${documentId}`);
      if (data.processing === false) {
        clearInterval(interval);
        window.location.href = `/documents/editor/${documentId}`;
      }
    }, 1000);
  };

  const renderLoading = () => {
    return (
      <div className="loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };

  const renderTemplate = (template) => {
    return (
      <div className="tile is-parent">
        <article className="tile is-child notification">
          <p className="title">{template.name}</p>
          {template.metadata.description && (
            <p className="subtitle">{template.metadata.description}</p>
          )}
          {template.metadata.categories &&
            template.metadata.categories.map((category) => (
              <span className="tag is-white">{category}</span>
            ))}
          <figure className="image">
            <img src={template.thumbnail} />
          </figure>
          <br />
          <button
            id="template-button-click"
            onClick={() => onTemplateSelected(template)}
            className={`button is-fullwidth use_template ${
              processing && processing.id === template.id ? 'is-loading' : ''
            }`}
          >
            Use Template
          </button>
        </article>
      </div>
    );
  };

  const renderTemplatesGrid = () => {
    return (
      <div className="columns is-gapless">
        <div className="column">
          {filteredTemplates &&
            filteredTemplates.map((template, idx) => {
              if (idx % 3 === 0) {
                return renderTemplate(template);
              }
            })}
        </div>
        <div className="column">
          {filteredTemplates &&
            filteredTemplates.map((template, idx) => {
              if (idx % 3 === 1) {
                return renderTemplate(template);
              }
            })}
        </div>
        <div className="column">
          {filteredTemplates &&
            filteredTemplates.map((template, idx) => {
              if (idx % 3 === 2) {
                return renderTemplate(template);
              }
            })}
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <aside className="menu">
        {/* Commenting out as this will be reinstanted shortly as per https://lindenhouse.atlassian.net/browse/SPR-8520 */}
        {/* <div className="field">
          <p className="control has-icons-left">
            <input
              className="input"
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-search"></i>
            </span>
          </p>
        </div> */}
        <p className="menu-label">Categories</p>
        <ul className="menu-list">
          <li>
            <a
              className={selectedCategory === 'All' ? 'is-active' : ''}
              onClick={(e) => setSelectedCategory('All')}
            >
              All
            </a>
          </li>
          {categories &&
            categories.map((category) => (
              <li>
                <a
                  id="category-listitem-click"
                  className={selectedCategory === category ? 'is-active' : ''}
                  onClick={(e) => {
                    e.preventDefault();
                    if (selectedCategory !== category) {
                      setSelectedCategory(category);
                    }
                  }}
                >
                  {category}
                </a>
              </li>
            ))}
        </ul>
      </aside>
    );
  };
  const renderLayout = () => {
    return (
      <div className="columns">
        <div className="column is-one-fifth templates_sidebar">
          {renderSidebar()}
        </div>
        <div className="column templates_grid">{renderTemplatesGrid()}</div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnOverlayClick={false}
      center
      classNames={{
        overlay: 'form_library_overlay',
        modal: 'form_library_modal'
      }}
    >
      <div className="modal_top">
        <h3 className="title is-h3">Form Library</h3>
      </div>
      <div className="form_library_content">
        {loading ? renderLoading() : renderLayout()}
      </div>
    </Modal>
  );
};
export default FormLibraryModal;
